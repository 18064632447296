<template>
  <div>
    <div>
      <b-row class="d-flex justify-content-center">
        <b-col cols="12" lg="8">
          <b-card
            style="height: 100%"
            class="h-md-300 dashboard dashboard-upk"
            header-tag="header"
            header-bg-variant="transparent"
          >
            <template>
              <h5 style="text-align: center;">
                Tanggal Bulan Berjalan: 
                {{ $moment(this.date).format("MMMM") }}
              </h5>
            </template>
            <b-row class="d-flex justify-content-end">
              <b-col cols="12" lg="3" md="3">
                <date-picker
                  type="month"
                  class="form-control"
                  format="YYYY-MM"
                  placeholder="Cari Tgl Transaksi"
                  :need-label="true"
                  v-model="date"
                />
              </b-col>
            </b-row>
            <div style="position: relative">
              <div>
                <BarChart
                  :datas="chartDataPenjualan.datasets"
                  :labels="chartDataPenjualan.labels"
                />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import BarChart from "@/components/BarVue.vue";
import Order from "@/models/Order";
import Loading from "@/components/Loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

const OrderModel = new Order();

export default {
  name: "vueChart",
  components: {
    BarChart,
    Loading,
    DatePicker,
  },
  data() {
    return {
      date: "",
      InputTimeFilter: 0,
      InputTimeFilterDari: "",
      InputTimeFilterKe: "",
      datasets: [],
      chartDataPenjualan: {
        labels: [],
        datasets: [],
        options: {
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              },
            },
          },
        },
      },
    };
  },
  created() {
    if (this.$store.getters["account/accountData"].role === "teller")
      this.$router.push("/transaksi");
    this.date = this.$moment(new Date()).format("YYYY-MM");
    this.getDashboard();
  },
  methods: {
    getDashboard: function() {
      this.chartDataPenjualan.labels = []
      this.chartDataPenjualan.datasets = []
      OrderModel.getDashboardDt(this.date).then((resp) => {
        resp.data.map((data) => {
          this.chartDataPenjualan.labels.push(data.date);
          this.chartDataPenjualan.datasets.push(parseInt(data.count));
        });
      });
    },
  },
  watch: {
    InputTimeFilter(newValue, oldValue) {
      this.InputTimeFilterDari = this.$moment(newValue[0]).format("YYYY-MM-DD");
      this.InputTimeFilterKe = this.$moment(newValue[1]).format("YYYY-MM-DD");
      if (newValue[0] != null && newValue[1] != null) {
        alert("jalankan fungsi filter");
      }
    },
    date: function(value) {
      this.date = this.$moment(value).format("YYYY-MM")
      this.getDashboard()
    }
  },
  mounted: function() {
  },
};
</script>

<style scoped></style>
