var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LineChartGenerator',{ref:"chart",attrs:{"chart-options":_vm.chartOptions,"chart-data":{
    labels: _vm.labels,
    datasets: [
      {
        data: _vm.datas,
        backgroundColor: ['#9900cc'],
        borderColor: ['rgba(153, 102, 255, 0.2)'],
        borderWidth: 2,
      } ],
  },"chart-id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":_vm.width,"height":_vm.height}})}
var staticRenderFns = []

export { render, staticRenderFns }