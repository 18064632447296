<template>
  <b-row
    v-if="hidden"
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <div class="">
      <b-spinner
        small
        label="Small Spinner"
        type="grow"
        variant="primary"
        class="mr-1"
      ></b-spinner>
      <b-spinner
        small
        label="Small Spinner"
        type="grow"
        variant="primary"
        class="mr-1"
      ></b-spinner>
      <b-spinner
        small
        label="Small Spinner"
        type="grow"
        variant="primary"
        class="mr-1"
      ></b-spinner>
    </div>
    <h4>Mohon Tunggu</h4>
  </b-row>
  <b-row
    v-else
    class="justify-content-center align-items-center text-center"
    style="height: 80vh;"
  >
    <b-col cols="12">
      <div class="mb-3">
        <b-spinner
          small
          label="Small Spinner"
          type="grow"
          variant="primary"
          class="mr-1"
        ></b-spinner>
        <b-spinner
          small
          label="Small Spinner"
          type="grow"
          variant="primary"
          class="mr-1"
        ></b-spinner>
        <b-spinner
          small
          label="Small Spinner"
          type="grow"
          variant="primary"
          class="mr-1"
        ></b-spinner>
      </div>
      <h4>Mohon tunggu</h4>
      <p>Sedang memuat halaman...</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    hidden: Boolean,
  },
};
</script>
